export const yupNumberFieldMax = (
  fieldName: string,
  maxCharacters: number
): string =>
  `Campo ${fieldName} deve conter no máximo ${maxCharacters} caracteres`

export enum Messages {
  /**
   * Validations: Yup
   */
  YUP_REQUIRED_FIELD = 'Campo obrigatório',
  YUP_INVALID_EMAIL = 'E-mail inválido',
  YUP_PASSWORD_INVALID_MATCH = 'As senhas não são iguais',
  YUP_PASSWORD_INVALID_CRITERIA = 'A senha deve seguir os critérios acima',
  YUP_NUMBER_REQUIRED = 'Campo deve ser numérico',

  /**
   * Validations: Password
   */
  PASSWORD_CRITERIA = 'A senha deve ter de 8 a 16 caracteres e incluir pelo menos um número, uma letra minúscula e uma letra maiúscula.',
  SCREEN_FIRST_ACCESS = 'Notamos que este é seu primeiro acesso. Por favor defina uma nova senha para acessar o sistema.',
  SCREEN_REDEFINE_PASSWORD = 'Por favor defina uma nova senha para acessar o sistema.',

  /**
   * Validations: Date
   */
  DATE_INITIAL_INVALID_RANGE = 'A data inicial não pode ser maior do que a final.',
  DATE_FINAL_INVALID_RANGE = 'A data final não pode ser menor do que a inicial.',

  /**
   * General
   */
  ERROR_MESSAGE = 'Ocorreu um erro inesperado. Atualize a página e tente novamente.',
  DATA_UPDATE = 'Alterações realizadas com sucesso.',
  FILTER_BY_STOCK_NUMBER = 'Insira um nº de plantel válido. Caso exista, o campo será preenchido com o ID do registro.',
  REQUIRED_FIELDS = 'Alguns campos obrigatórios não foram preenchidos.',
  ERROR_SHEET_MESSAGE = 'Ocorreu um erro ao importar os dados da planilha. Por favor, tente novamente.',
  EMPTY_DATA = 'Nenhum dado encontrado.',
  GENERATING_EXPORT_FILE = 'Gerando arquivo de exportação. Aguarde alguns instantes.',

  /**
   * Animals
   */
  ANIMAL_NOT_ADDED = 'Nenhum animal incluído ainda.',
  ANIMAL_NOT_FOUND = 'Animal não encontrado.',
  ANIMAL_CREATE_SUCCESS = 'com sucesso.',
  ANIMAL_ADD_SUCCESS = 'Animais adicionados com sucesso.',
  ANIMAL_ADD_SINGLE_SUCCESS = 'Animais adicionados com sucesso.',
  ANIMAL_REMOVE_SUCCESS = 'Animal removido com sucesso.',
  ANIMAL_UPDATE_SUCCESS = 'Animal atualizado com sucesso.',
  ANIMAL_UPDATE_CONFLICT = 'Já existe um animal cadastrado com as informações preenchidas.',
  ANIMAL_CREATE_FAIL = 'Não foi possível cadastrar.',
  NOT_FOUND_BREEDING_ANIMALS_FILTER = 'Animal não adicionado.\nVerifique se o animal está apto para reprodução e se não pertence a nenhuma estação de monta ativa',
  NOT_FOUND_FILTER_ANIMALS = 'Animais não encontrados, ou já foram adicionados.',
  ANIMAL_DELETE_DISABLED = 'No mínimo 1 animal deve ser escolhido.',
  ANIMAL_COMPOSITE_BREED_TOOLTIP = 'Use Raça Composta se o animal tiver mais que 2 raças.',
  ANIMAL_BREED_TOOLTIP = 'Caso animal tenha uma segunda raça, ajuste a porcentagem da Raça 1.',
  ANIMAL_SEARCHING = 'Procurando animais com base nos filtros estabelecidos.',
  ANIMALS_NOT_FOUND = 'Nenhum animal encontrado.',
  ANIMAL_SEARCH_MULTIPLE_FILTERS = 'Pesquise por número de plantel, nascimento ou brinco eletrônico...',
  ANIMAL_EXPORTED_SUCCESS = 'Animais exportados com sucesso.',
  ANIMALS_UPDATING = 'Atualizando animais. Aguarde alguns instantes.',
  ANIMALS_HERD_DYNAMIC_HINT = 'O período selecionado não pode exceder três meses.',
  ANIMALS_IMPORT_SUCCESSFUL = 'Animais importados com sucesso.',

  /**
   * Shutdown
   */
  SHUTDOWN_CONFIRMATION = 'Você tem certeza que deseja baixar os animais?\nApós inativá-los, os históricos dos animais serão mantidos na Ficha do Animal.',
  SHUTDOWN_SUCCESS = 'Baixa de animais efetuada com sucesso.',
  SHUTDOWN_IMPORT_SUCCESSFUL = 'Baixas importadas com sucesso.',

  /**
   * Handlings
   */
  HANDLING_SUCCESS = 'Manejo criado com sucesso.',
  HANDLING_NOT_FOUND = 'Nenhum manejo encontrado.',
  HANDLING_IMPORT_SUCCESSFUL = 'Manejos importados com sucesso.',
  HANDLING_EXPORT_SUCCESS = 'Manejos exportados com sucesso.',

  /**
   * Breeding (General)
   */
  BREEDING_ANIMALS_ADDED = ANIMAL_ADD_SUCCESS,
  BREEDING_ANIMAL_REMOVE_SUCCESS = ANIMAL_REMOVE_SUCCESS,
  BREEDING_ANIMAL_NOT_FOUND = 'Nenhum animal ativo encontrado, ou não está vinculada à estação de monta.',
  BREEDING_STATION_ADDING_ANIMALS = 'Inserindo animais na estação de monta. Aguarde alguns instantes.',
  BREEDING_STATION_UPDATE_SUCCESS = 'Estação de Monta atualizada com sucesso.',
  BREEDING_GROUP_ADDING_ANIMALS = 'Inserindo animais no grupo de repasse. Aguarde alguns instantes.',
  BREEDING_STATION_NOT_FOUND = 'Nenhuma estação de monta encontrada.',
  BREEDING_GROUP_NOT_FOUND = 'Nenhum grupo de repasse encontrado.',
  BREEDING_GROUP_INACTIVE_TOOLTIP = 'Não é possível adicionar animais em um Grupo de Repasse inativo.',
  BREEDING_GROUP_CREATE_INACTIVE_STATION = 'Não é possível criar um Grupo de Repasse para uma Estação de Monta inativa.',
  BREEDING_GROUP_DELETED_SUCCESS = 'Grupo de Repasse excluído com sucesso.',
  BREEDING_STATION_INACTIVE_TOOLTIP = 'Não é possível adicionar animais em uma Estação de Monta inativa.',
  BREEDING_STATION_ONLY_ADMIN_CAN_ADD_ANIMALS = 'Somente administradores podem adicionar animais em uma Estação de Monta inativa.',
  BREEDING_STATION_ONLY_ADMIN_CAN_CREATE = 'Somente administradores podem criar uma Estação de Monta.',
  BREEDING_STATION_DELETED_SUCCESS = 'Estação de Monta excluída com sucesso.',
  BREEDING_DIAGNOSTICS_INACTIVE_TOOLTIP = 'Não é possível adicionar animais em um Grupo de Diagnósticos já registrado.',
  BREEDING_DIAGNOSTICS_REQUIRED = 'No mínimo 1 diagnóstico deve ser inserido.',
  BREEDING_DIAGNOSTICS_IMPORT_SUCCESSFUL = 'Diagnósticos importados com sucesso.',
  BREEDING_DIAGNOSTICS_DELETED_SUCCESS = 'Diagnóstico excluído com sucesso.',
  BREEDING_DIAGNOSTICS_GROUP_DELETED_SUCCESS = 'Grupo de diagnósticos excluído com sucesso.',
  BREEDING_DIAGNOSTICS_EXPORT_SUCCESS = 'Diagnósticos exportados com sucesso.',

  /**
   * Hormonal (General)
   */
  HORMONAL_ANIMAL_NOT_FOUND = BREEDING_ANIMAL_NOT_FOUND,

  /**
   * Hormonal (Insemination)
   */
  BREEDING_STATION_REQUIRED = 'Selecione uma estação de monta e tente novamente.',
  INSEMINATION_NOT_FOUND = 'Nenhum lançamento de cobertura/inseminação encontrado.',
  INSEMINATION_ANIMALS_REQUIRED = 'No mínimo 1 animal deve ser inserido.',
  INSEMINATION_SEMEN_NOT_FOUND = 'Sêmen não encontrado.',
  INSEMINATION_IMPORT_SUCCESSFUL = 'Inseminações/coberturas importadas com sucesso.',
  INSEMINATION_INACTIVE_TOOLTIP = 'Não é possível adicionar animais em um Grupo de Inseminações já registrado.',
  INSEMINATION_DELETED_SUCCESS = 'Registro excluído com sucesso.',
  INSEMINATION_GROUP_DELETED_SUCCESS = 'Grupo de inseminações/coberturas excluído com sucesso.',

  /**
   * Protocols
   */
  PROTOCOL_NOT_FOUND = 'Nenhum protocolo encontrado.',
  PROTOCOL_CREATE_SUCCESS = 'Protocolo criado com sucesso. Em seguida inclua os dias/ações.',
  PROTOCOL_ACTION_EMPTY = 'Nenhuma ação foi incluída ainda.',
  PROTOCOL_ACTION_CREATE_SUCCESS = 'Dia do protocolo adicionado com sucesso.',
  PROTOCOL_ACTION_DELETE_SUCCESS = 'Dia do protocolo excluído.',
  PROTOCOLS_NOT_FOUND = 'Nenhum protocolo encontrado.',

  /**
   * IATF Schedule
   */
  SCHEDULE_ADD_ANIMAL = ANIMAL_ADD_SUCCESS,
  SCHEDULE_REMOVE_ANIMAL = ANIMAL_REMOVE_SUCCESS,
  SCHEDULE_ANIMAL_NOT_FOUND = 'Nenhuma vaca ativa encontrada, ou não está vinculada à estação de monta.',
  SCHEDULE_TOOLTIP_IMPLANTED = 'Mude para "Sim" caso o animal tenha perdido o implante.',
  SCHEDULE_NOT_FOUND = 'Nenhuma programação encontrada.',
  SCHEDULE_DELETE_SUCCESS = 'Programação IATF excluída com sucesso.',

  /**
   * Births
   */
  BIRTHS_ADD_BIRTH_SUCCESS = 'Registro de parto criado com sucesso.',
  BIRTHS_UPDATE_SUCCESS = 'Registro de parto atualizado com sucesso.',
  BIRTHS_REQUIRED_FIELDS = REQUIRED_FIELDS,
  BIRTHS_ANIMAL_NOT_ADDED = ANIMAL_NOT_ADDED,
  BIRTHS_CREATED_SUCCESSFUL = 'com sucesso.',
  BIRTHS_CREATED_FAILED = 'Não foi possível inserir ',
  BIRTHS_UPDATE_REQUIRED_FIELDS = REQUIRED_FIELDS,
  BIRTHS_NOT_FOUND = 'Nenhum parto encontrado.',
  BIRTHS_DELETED_SUCCESS = 'Parto excluído com sucesso',
  BIRTHS_MAX_DATE_INVALID = 'Não é possível inserir uma data de parto superior ao período máximo de gestação.',

  /**
   * Mating Recommendations
   */
  MATING_RECOMMENDATIONS_NOT_FOUND = 'Nenhuma recomendação de acasalamento encontrada.',
  MATING_RECOMMENDATION_EMPTY_DATA = 'Nenhum animal selecionado para recomendação.',
  MATING_RECOMMENDATION_CREATED_SUCCESSFUL = 'Recomendação de acasalamento criada com sucesso.',
  MATING_RECOMMENDATION_EDIT_SUCCESSFUL = 'Recomendação de acasalamento atualizada com sucesso.',

  /**
   * Evaluations
   */
  EVALUATION_CREATED_SUCCESSFUL = 'Avaliação criada com sucesso.',
  EVALUATIONS_NOT_FOUND = 'Nenhuma avaliação encontrada.',
  EVALUATION_SCORE_REQUIRED = 'É necessário selecionar uma nota antes de enviar.',
  EVALUATION_EMPTY_DATA = 'Não há registro de avaliação para este animal.',
  EVALUATION_INACTIVE_ANIMAL_MESSAGE = 'Não é possível avaliar um animal inativo.',
  EVALUATION_CALF_EMPTY_DATA = 'Não há registro de avaliações para essa cria.',
  EVALUATION_ANIMAL_DISPOSAL_SUCCESS = 'Animal enviado para o setor de descarte.',

  /**
   * Animal Search Component
   */
  ANIMAL_SEARCH_ONLY_FEMALE = 'Selecione apenas fêmeas para este campo. Por favor, revise e tente novamente.',

  /**
   * Collective Movements
   */
  COLLECTIVE_MOVEMENTS_ANIMAL_NOT_ADDED = ANIMAL_NOT_ADDED,
  COLLECTIVE_MOVEMENTS_SELECT_ORIGIN = 'Selecione um setor de origem para continuar.',
  COLLECTIVE_MOVEMENTS_CREATED_SUCCESSFUL = 'Movimentação coletiva criada com sucesso.',
  COLLECTIVE_MOVEMENTS_DESTINATION_REQUIRED = 'Selecione uma fazenda de destino e/ou setor de destino para continuar.',
  COLLECTIVE_MOVEMENTS_CREATING = 'Criando movimentação coletiva. Aguarde alguns instantes.',

  /**
   * System Parameters
   */
  SYSTEM_BREED_CREATE_SUCCESS = 'Raça criada com sucesso.',
  SYSTEM_BREED_UPDATE_SUCCESS = 'Raça atualizada com sucesso.',
  SYSTEM_BREED_DELETE_SUCCESS = 'Raça deletada com sucesso.',
  SYSTEM_BREED_ABBREVIATION_CHARS_LIMIT = 'Abreviação deve ter exatamente 2 caracteres',
  SYSTEM_ASSOCIATION_CREATE_SUCCESS = 'Associação criada com sucesso.',
  SYSTEM_FARM_CREATE_SUCCESS = 'Fazenda criada com sucesso.',
  SYSTEM_FARM_EDIT_SUCCESS = 'Fazenda atualizada com sucesso.',
  SYSTEM_SECTOR_CREATE_SUCCESS = 'Setor criado com sucesso.',
  SYSTEM_SECTOR_EDIT_SUCCESS = 'Setor atualizado com sucesso.',
  SYSTEM_REPRODUCTION_UPDATE_BIRTH_FORECAST = 'Atualização das previsões de parto em andamento',
  SYSTEM_UPDATE_SUCCESS = 'Parâmetros atualizados com sucesso.',
  SYSTEM_REPRODUCTION_ANESTRUS_MINIMUM = 'O limite de anestro mínimo é de 30 dias',
  SYSTEM_REPRODUCTION_GESTATION_DIAGNOSTIC_MINIMUM = 'O limite de diagnósticos de gestação mínimo é de 28 dias',
  SYSTEM_FARM_READ_ZIP_CODE_ERROR = 'Não foi possível obter informações do endereço. Por favor, verifique o CEP e tente novamente.',
  SYSTEM_SHUTDOWN_RELATION_ERROR = 'O motivo não pode ser excluído pois está vinculado a algum animal.',
  ZIP_CODE_EXACT = 'Cep deve conter 8 caracteres',
  SYSTEM_SERVICE_CREATE_SUCCESS = 'Serviço criado com sucesso.',
  SYSTEM_SERVICE_UPDATE_SUCCESS = 'Serviço atualizado com sucesso.',
  SYSTEM_SERVICE_DELETE_SUCCESS = 'Serviço excluído com sucesso.',
  SYSTEM_LOCATION_CREATE_SUCCESS = 'Local criado com sucesso.',
  SYSTEM_LOCATION_UPDATE_SUCCESS = 'Local atualizado com sucesso.',
  SYSTEM_LOCATION_DELETE_SUCCESS = 'Local excluído com sucesso.',
  SYSTEM_PARAMETERS_ONLY_ADMIN = 'Somente usuários administradores podem acessar essa página.',
  SYSTEM_PARAMETERS_IMPORT_EMPTY_DATA = 'Nenhum parâmetro foi importado até o momento.',
  SYSTEM_EXECUTORS_IMPORT_SUCCESS = 'Executores importados com sucesso.',
  SYSTEM_CLASSIFICATIONS_IMPORT_SUCCESS = 'Classificações importadas com sucesso.',
  SYSTEM_COST_CENTER_IMPORT_SUCCESS = 'Centros de custos importados com sucesso.',
  SYSTEM_MACHINERY_IMPORT_SUCCESS = 'Maquinários importados com sucesso.',

  /**
   * Dashboard
   */

  DASHBOARD_COUNT_REQUIREMENTS_FAIL = 'Pelo menos um grupo de idade é necessário para acessar essa página',
  DASHBOARD_ANIMAL_UNIT_REQUIREMENTS_FAIL = 'Pelo menos um grupo de idade e uma UA média precisam ser configuradas para acessar essa página',
  DASHBOARD_PRECIPITATION_EMPTY_DATA = 'Nenhum dado de precipitação disponível.',

  /**
   * Precipitations
   */
  PRECIPITATIONS_STATION_REQUIRED = 'Selecione uma ou mais estações para visualizar as informações de precipitações.',
  PRECIPITATIONS_MEASURE_CREATE_SUCCESS = 'Lançamento manual criado com sucesso.',
  PRECIPITATIONS_MEASURE_UPDATE_SUCCESS = 'Lançamento manual atualizado com sucesso.',
  PRECIPITATIONS_MEASURE_DELETE_SUCCESS = 'Lançamento manual excluído com sucesso.',

  /**
   * Station measurement
   */
  STATION_MEASUREMENT_CREATE_SUCCESS = 'Estação de medição criada com sucesso.',
  STATION_MEASUREMENT_EDIT_SUCCESS = 'Estação de medição atualizada com sucesso.',
  STATION_MEASUREMENT_DELETE_SUCCESS = 'Estação de medição excluída com sucesso.',

  /**
   * Service Order
   */
  SERVICE_ORDER_CREATE_SUCCESS = 'Ordem de serviço criada com sucesso.',
  SERVICE_ORDER_UPDATE_SUCCESS = 'Ordem de serviço atualizada com sucesso.',
  SERVICE_ORDER_NOT_FOUND = 'Não há boletins lançados nesse período.',
  SERVICE_ORDER_NOT_FOUND_FILTERED = 'Nenhum boletim encontrado para o filtro informado.',
  SERVICE_ORDER_EXPORTED_SUCCESS = 'Boletins de serviço exportados com sucesso.',

  /**
   * User
   */
  USER_UPDATE_SUCCESS = 'Usuário atualizado com sucesso.',

  /**
   * Inseminator
   */
  INSEMINATOR_CREATE_SUCCESS = 'Inseminador criado com sucesso.',
  INSEMINATOR_UPDATE_SUCCESS = 'Inseminador atualizado com sucesso.',
  INSEMINATOR_EXPORT_SUCCESS = 'Inseminadores exportados com sucesso.',
}
