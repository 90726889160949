/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useState } from 'react'

import { SelectedFiles } from 'use-file-picker'

import { postImportCheck, postImportUser } from 'app/core/services'
import { postImportClassification } from 'app/core/services/system/classification'
import { postImportCostCenters } from 'app/core/services/system/cost-center'
import {
  getImportedFile,
  getImportedParameters,
} from 'app/core/services/system/imported-parameter'
import { postImportMachinery } from 'app/core/services/system/machinery'
import { ImportHook } from 'app/core/types/hooks'
import {
  ActiveImportProps,
  ImportEntity,
  ImportHistoryResponse,
  ImportParameterResponse,
  ImportedParameterType,
} from 'app/core/types/system'
import {
  addToast,
  dateForFileName,
  downloadFile,
  generateXlsxTemplate,
  getPlural,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useImport = (): ImportHook => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [activeImport, setActiveImport] = useState<ActiveImportProps>()
  const [importedFiles, setImportedFiles] = useState<ImportHistoryResponse[]>(
    []
  )

  const handleImportFile = async (
    file: SelectedFiles,
    entity: ImportEntity
  ): Promise<void> => {
    try {
      const { added, changed, disables, has_investment } =
        await postImportCheck(file, entity)

      setActiveImport(prevState => ({
        ...prevState,
        file,
        fileName: file.filesContent[0].name,
        added,
        changed,
        disables,
        has_investment,
      }))

      setIsModalOpen(true)
    } catch (e) {
      handleHttpError(e)
    }
  }

  const toggleModal = (): void => {
    setIsModalOpen(prevState => !prevState)
    setActiveImport(undefined)
  }

  const loadImportedParameters = useCallback(
    async (type: ImportedParameterType) => {
      try {
        setIsLoading(true)

        const importedParameters = await getImportedParameters(type)

        setImportedFiles(importedParameters)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const importExecutors = useCallback(async () => {
    if (activeImport) {
      try {
        setIsLoading(true)

        const data = await postImportUser(activeImport?.file)

        setIsModalOpen(false)

        checkInconsistencies(data)

        addToast({
          message: Messages.SYSTEM_EXECUTORS_IMPORT_SUCCESS,
          type: 'success',
        })

        setActiveImport(undefined)
        loadImportedParameters(ImportedParameterType.executor)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    }
  }, [activeImport, loadImportedParameters])

  const importClassification = useCallback(async () => {
    if (activeImport) {
      try {
        setIsLoading(true)

        const data = await postImportClassification(activeImport?.file)

        setIsModalOpen(false)

        checkInconsistencies(data)

        addToast({
          message: Messages.SYSTEM_CLASSIFICATIONS_IMPORT_SUCCESS,
          type: 'success',
        })

        setActiveImport(undefined)
        loadImportedParameters(ImportedParameterType.classification)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    }
  }, [activeImport, loadImportedParameters])

  const importCostCenters = useCallback(async () => {
    if (activeImport) {
      try {
        setIsLoading(true)

        const data = await postImportCostCenters(activeImport?.file)

        setIsModalOpen(false)

        checkInconsistencies(data)

        addToast({
          message: Messages.SYSTEM_COST_CENTER_IMPORT_SUCCESS,
          type: 'success',
        })

        setActiveImport(undefined)
        loadImportedParameters(ImportedParameterType.cost_center)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    }
  }, [activeImport, loadImportedParameters])

  const importMachinery = useCallback(async () => {
    if (activeImport) {
      try {
        setIsLoading(true)

        const data = await postImportMachinery(activeImport?.file)

        setIsModalOpen(false)

        checkInconsistencies(data)

        addToast({
          message: Messages.SYSTEM_MACHINERY_IMPORT_SUCCESS,
          type: 'success',
        })

        setActiveImport(undefined)
        loadImportedParameters(ImportedParameterType.machinery)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    }
  }, [activeImport, loadImportedParameters])

  const downloadImportedParameterFile = useCallback(
    async (fileName: string) => {
      try {
        setIsLoading(true)

        const { data } = await getImportedFile(fileName)

        downloadFile({
          data: data,
          fileName: fileName.split('.')[0],
        })
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const checkInconsistencies = (data: ImportParameterResponse[]): void => {
    const hasInconsistencies = data.length > 0 && data.some(item => item.error)
    const inconsistencies = data.filter(item => item.error).length

    if (hasInconsistencies) {
      const headers = Object.keys(data[0]).map(key =>
        key === 'error' ? 'Inconsistência' : key
      )
      const items = data.map(item => {
        return headers.map(header => {
          if (header === 'Inconsistência') return item.error
          return item[header] !== undefined ? item[header] : null
        })
      })

      const xlsx = generateXlsxTemplate([headers, ...items])

      downloadFile({
        data: xlsx,
        fileName: `inconsistencias-${dateForFileName()}`,
      })

      addToast({
        message: `Não foi possível importar ${getPlural(
          'item',
          inconsistencies,
          true
        )}. Verifique a planilha de inconsistências e tente novamente.`,
      })
    }
  }

  return {
    activeImport,
    handleImportFile,
    importExecutors,
    importClassification,
    importCostCenters,
    importMachinery,
    isModalOpen,
    toggleModal,
    isLoading,
    importedFiles,
    loadImportedParameters,
    downloadImportedParameterFile,
  }
}

export { useImport }
